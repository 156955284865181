<template>
	<!-- 测试- 需求详情-->
	<GModule class="container">
		<GTitle name="需求详情" style="margin: 20px;"></GTitle>
		<div class="bug-box">
			<a-form
				class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 7}"
			    :wrapper-col="{ span: 12 }"
			    autocomplete="off"
				:scrollToFirstError='true'
			    @finish="onFinish"
			    @finishFailed="onFinishFailed">
				<a-form-item
					label="需求描述" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: true, message: '请输入需求描述' }]">
				    <a-textarea placeholder="请输入需求描述" style="height: 200px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item
					label="参数/逻辑说明" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required:false}]">
				    <a-textarea placeholder="请输入参数/逻辑说明" style="height: 200px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item
					label="需求图片" 
					name="imgList" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    <div class="img-scroll" @click="openSwiper()">
				    	<a-image :preview="false" :width="90" :src="itm.url" v-for="(itm,ind) in imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item
					label="需求类型" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    需求类型
				</a-form-item>
				<a-form-item
					label="需求模块" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    <a-breadcrumb separator=">" style="margin-bottom: 14px;">
				        <a-breadcrumb-item>需求</a-breadcrumb-item>
				        <a-breadcrumb-item>需求2</a-breadcrumb-item>
				        <a-breadcrumb-item>Application List</a-breadcrumb-item>
				        <a-breadcrumb-item>An Application</a-breadcrumb-item>
				    	<a-breadcrumb-item>需求</a-breadcrumb-item>
				    	<a-breadcrumb-item>需求2</a-breadcrumb-item>
				    	<a-breadcrumb-item>Application List</a-breadcrumb-item>
				    	<a-breadcrumb-item>An Application</a-breadcrumb-item>
				     </a-breadcrumb>
				</a-form-item>
				<a-form-item
					label="需求ID" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    需求ID
				</a-form-item>
				<a-form-item
					label="相关用例" 
					name="imgList" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    <div class="case-box">
				    	<a-tabs v-model:activeKey="activeKey">
				    		<a-tab-pane v-for="(i,index) in caseList" :key="index" :tab="i.title">
								顶顶顶顶顶顶顶顶到底多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多到底多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多多顶顶顶顶到底多多多多多多多多多多
							</a-tab-pane>
				    	</a-tabs>
				    </div>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }">
					<a-button class="btn clo6">返回</a-button>
				    <a-button class="btn clo3" html-type="submit">建用例</a-button>
				</a-form-item>
			</a-form>
		</div>
	</GModule>
</template>

<script>
export default{
	data(){
		return{
			productArr:[
			    {title:"一级"},
			    {title:"二级"},
			    {title:"三级"},
			    {title:"四级"},
			    {title:"五级"}
			], 
			stateIndex:0,
			manArr:[
			    {title:"张三"},
			    {title:"李四"},
			], 
			manIndex:0,
			previewVisible:false,
			previewImage:'',
			formState :{
				bug:'',
				man:'',
				describe:'',
				explain:'',
				imgList:[]
			},
			caseList:[
			    {title:"用例1"},
			    {title:"用例2"},
			    {title:"用例3"},
			    {title:"用例4"},
			    {title:"用例5"},
				{title:"用例1"},
				{title:"用例2"},
				{title:"用例3"},
				{title:"用例4"},
				{title:"用例5"}
			], 
			activeKey:0,
			imgArr:[
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
				{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false}
			]
		}
	},
	methods:{
		openSwiper(){
		  // 弹出图片列表
		  this.$store.commit('setSwiper',1)
		}
	}
}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0;
	overflow: hidden;
	.bug-box{
		display: flex;
		justify-content: center;
		max-height:550px;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.need-box{
			.btn{
				margin: 30px 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;
					
					}
				}
			}
		}
	}
}
</style>